@import "./react-datepicker.min.css";

:root {
    --white: #fff;
    --textBlack: #141414;
    --textGrey: #464E5B;
    --blue: #425CC7;
    --hoverBlue: #364fb7;
    --black:#000;
}


/* popup window*/
.react-datepicker-popper{
    padding-top: 0;
    z-index: 9999;
}

.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 0;
}

.react-datepicker-popper[data-placement^=top]{
    padding-bottom: 0;
}

/*container*/
.react-datepicker{
    background: var(--white);
    font-family: "Brother-1816",sans-serif;
    box-shadow: 0 1px 2px rgba(0, 14, 51, 0.25);
    border-radius: 8px;
    border: 1px solid #EDEEEF;
    overflow: visible;
    color: var(--textBlack);
    display: flex;
    flex-direction: column;
}

/*main container inside react-datepicker*/
.react-datepicker__month-container{
    padding-bottom: 16px;
    width:426px;
}

/*header*/

.react-datepicker__header{
    border-bottom: unset;
    background: var(--white);
    position:static;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px !important;
    padding-top: 16px;
}

.react-datepicker__header__dropdown{
    display: flex;
    justify-content: center;
}

/*single day container*/
.react-datepicker__day{
    min-width: 48px;
    width:48px;
    height: 44px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 10px;
    background-color: var(--white);
    margin: 2px;
    color: var(--black)
}

.react-datepicker__day:hover{
    border-radius: 10px;
}

/* focused, selected and hovered day */
.react-datepicker__day[tabindex="0"],
.currentlySelected.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:not(.react-datepicker__day[tabindex="-1"])
{
    background-color: var(--blue);
    color: white;
}


/*day names container*/
.react-datepicker__day-names{
    margin-top: 7px;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

/*single day name sun, mon etc..*/
.react-datepicker__day-name{
    width:50px;
    height: 44px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color:#3C3C434D;
}

.react-datepicker__day--disabled{
    color: #00175426;
    cursor: not-allowed;
}

/*selected month displayed on top*/
.react-datepicker__current-month{
    display:none
}

/*dropdown buttons*/
.react-datepicker__month-dropdown-container,.react-datepicker__year-dropdown-container {
    padding: 7px 12px;
    box-shadow: 0 1px 1px rgba(0, 14, 51, 0.05);
    background-color: white;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin: 0 1px;
    max-height: 45px;
    box-sizing: border-box;
}

/*containers for selected month in dropdown buttons*/
.react-datepicker__year-read-view,.react-datepicker__month-read-view{
    visibility: visible !important;
}

.react-datepicker__year-read-view--selected-year,.react-datepicker__month-read-view--selected-month{
    margin-right:16px;
    position: relative;
    font-size: 24px;
    font-weight: bold;
}

/*dropdown buttons arrows*/
.react-datepicker__year-read-view--selected-year::after,.react-datepicker__month-read-view--selected-month::after{
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--blue);
    content: '';
    position: absolute;
    bottom: 2px;
    transform: translate(100%,-100%) rotate(180deg);
    clip-path:inset(10% 15% 0% 15% round 1px);
    right:-5px;
}

/*default arrows for dropdown buttons*/
.react-datepicker__year-read-view--down-arrow{
    display: none;
}

.react-datepicker__month-read-view--down-arrow{
    display: none;
}

/*visible days but not within month for example 29 in february*/
.react-datepicker__day--outside-month{
    visibility: hidden;
}

/*navigation arrows*/
.react-datepicker__navigation--previous,.react-datepicker__navigation--next{
    border-radius: 100%;
    background-color: white;
    background-size: contain;
    box-shadow: 0 1px 1px rgba(0, 14, 51, 0.05);
    width: 44px;
    height: 44px;
}

.react-datepicker__navigation--previous{
    background: white url("../../assets/icons/calendar_arrow_left.svg") no-repeat center;
    top:16px;
    left:24px;
}

.react-datepicker__navigation--next{
    background: white url("../../assets/icons/calendar_arrow_right.svg") no-repeat center;
    right:24px;
    top:16px;
}

/*default navigation icon*/
.react-datepicker__navigation-icon{
    display: none;
}

/*dropdowns containers*/
.react-datepicker__year-dropdown,.react-datepicker__month-dropdown{
    top:69px;
    max-width: fit-content;
    z-index: 9999;
    background-color: var(--white);
    box-shadow: 0 30px 84px rgba(19, 10, 46, 0.08), 0 8px 32px rgba(19, 10, 46, 0.07), 0 3px 14px rgba(19, 10, 46, 0.03), 0 1px 3px rgba(19, 10, 46, 0.13);
    border: unset;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    width:100%;
    left: 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    min-width: calc(100% - 32px);
    padding: 16px;
}

.react-datepicker__month-dropdown{
    grid-row-gap: 16px;
}

.react-datepicker__year-dropdown{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: calc(100% - 101px);
    grid-row-gap: 16px;
}

.react-datepicker__year-dropdown::-webkit-scrollbar{
    display: none;
}

.react-datepicker__year-option,.react-datepicker__month-option{
    width: fit-content;
    border-radius: 10px;
    font-size: 1.6rem;
    color: var(--textGrey);
}

.react-datepicker__year-option{
    padding: 8px 9px;
}

.react-datepicker__month-option{
    margin-left: 10px;
    padding:8px 11px;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.react-datepicker__year-option--selected_year,.react-datepicker__month-option--selected_month{
    background-color:  var(--blue);
    color: var(--white);
}

.react-datepicker__year-option:hover,.react-datepicker__month-option:hover{
    background-color: var(--hoverBlue);
    color: var(--white);
}

/*years dropdown arrow container*/
.react-datepicker__year-option:last-of-type{
    display: none;
}

/*default tick icon for selected month/year in dropdown*/
.react-datepicker__month-option--selected, .react-datepicker__year-option--selected{
    display: none;
}

.buttons-container{
    display: flex;
    justify-content: space-between;
    padding: 0 12px 16px;
}
@media(min-width:600px){
    .buttons-container > .MuiButton-root {
        padding: 16px 58px !important;
    }
}

/*mobile styles*/


.calendarDialog .buttons-container {
    background-color: var(--white);
    box-sizing: border-box;
    position: relative;
    order: 3;
    z-index: 2147483647;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 23px;
    padding: 10px 21px 34px;
    width: 100%;
    max-width: 426px;
}

.calendarDialog .MuiBackdrop-root {
    background-color: rgba(107, 114, 124, 0.24);
}

.calendarDialog .datepicker-title{
    width: 100%;
    max-width: 426px;
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    background-color: white;
    padding: 43px 0 17px;
    position: relative;
}

.calendarDialog .datepicker-title::before{
    position: absolute;
    content: "";
    width: 134px;
    height: 5px;
    top:16px;
    left:50%;
    transform: translateX(-50%);
    background-color: #E8ECF4;
    border-radius: 100px;
}


.calendarDialog .MuiPaper-root{
    display: flex;
    margin:0;
    border-radius: 16px 16px 0 0;
}

.calendarDialog .MuiDialog-container .react-datepicker-popper {
    position: relative !important;
    transform: unset !important;
}

.calendarDialog .MuiDialog-container{
    align-items: flex-end;
}

.calendarDialog .react-datepicker{
    border: unset;
    box-shadow: unset;
}


@media(max-width: 600px){
    .react-datepicker__day--keyboard-selected{
        background-color: var(--blue);
        color: var(--white)
    }

    .react-datepicker__day:hover[tabindex="0"]{
        background-color: var(--blue);
        color: var(--white)
    }

    .react-datepicker__day--keyboard-selected:hover{
        background-color: var(--blue);
        color: var(--white)
    }

    .react-datepicker__month-container{
        max-width: 375px;
    }

    .react-datepicker__day-names{
        margin: 8px auto 0;
    }
}
