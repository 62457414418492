@import "./ui-kit/src/components/CustomDatePicker/datePickerStyles.css";
@import "./fonts/Inter/inter.css";
@import "./fonts/Cairo/cairo.css";

@font-face {
  font-family: 'Brother-1816';
  src: url('./fonts/Brother1816Printed-Book.eot');
  src: local('Brother 1816'), local('Brother-1816'),
  url('./fonts/Brother1816Printed-Book.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Brother1816Printed-Book.woff') format('woff'),
  url('./fonts/Brother1816Printed-Book.ttf') format('truetype');
  font-style: normal;
}

html {
  font-size: 62.5%;
}

input[type='number'] {
  -moz-appearance:textfield;
}

body {
  background-color: #F6F6F7;
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-y: scroll;
  font-family: Brother-1816;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after{box-sizing:border-box;}

img {
  max-width: 100%;
  display: block;
}


#root {
  min-width: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}